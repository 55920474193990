import React, {
  useState,
  ReactElement,
  ChangeEvent,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Location } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import { logInStart, checkLoginStatus } from 'store/auth';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Card from 'components/atoms/Card';
import TextHeader from 'components/atoms/TextHeader';
import Button from 'components/atoms/Button';
import Base from 'components/templates/Base';
import Alert from 'components/atoms/Alert';
import { RootState } from 'store/configuration';
import { ROUTES } from 'components/templates/CustomRouter';

interface LocationState extends Location {
  from: Location;
}

const Login = (): ReactElement => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const firstRender = useRef(true);
  const { error, loggedIn } = useSelector((state: RootState) => state.auth);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(logInStart(username, password));
      return false;
    },
    [dispatch, password, username]
  );

  useEffect(() => {
    if (!loggedIn && firstRender.current) {
      dispatch(checkLoginStatus());
      firstRender.current = false;
    }
    if (error && !showAlert) {
      setShowAlert(true);
    }
    if (loggedIn && !firstRender.current) {
      history.replace(location.state?.from?.pathname || ROUTES.HOME.path);
    }
  }, [
    location,
    showAlert,
    setShowAlert,
    firstRender,
    dispatch,
    loggedIn,
    error,
    history,
  ]);

  return (
    <Base>
      <div className="w-full max-w-lg">
        <Alert type="warning" showAlert={showAlert}>
          <p className="text-sm">{t('error:loginFailed')}</p>
        </Alert>
        <div className="mb-8 ">
          <TextHeader size="large">{t('common:logIn')}</TextHeader>
          <p>{t('common:loginInfo')}</p>
        </div>
        <Card>
          <form className="px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
            <div className="mb-4">
              <Label forInput="username">{t('common:username')}</Label>
              <Input
                placeholder={t('common:username')}
                id="username"
                type="text"
                onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setUsername(event.target.value)
                }
                value={username}
              />
            </div>
            <div className="mb-6">
              <Label forInput="password">{t('common:password')}</Label>
              <Input
                placeholder={t('common:password')}
                id="password"
                type="password"
                onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setPassword(event.target.value)
                }
                value={password}
              />
            </div>
            <div className="flex items-center justify-between">
              <Button value={t('common:logIn')} type="submit">
                {t('common:logIn')}
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </Base>
  );
};

export default Login;
