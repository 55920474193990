import React, { useMemo } from 'react'
import Translate from '../molecules/Translate'
import GroupDynamicTriangle from './GroupDynamicTriangle'
import { ReportData, GroupDynamic, Coordinate, ColorScheme } from '../../types'

interface GroupDynamicsGraphProps {
  data: ReportData
  triangleClickedHandler?: (groupDynamic: GroupDynamic) => any
  offset: Partial<Coordinate>
  colorScheme?: ColorScheme
  currentGroupDynamic: GroupDynamic | null
}

export const GroupDynamicsGraph: React.FC<GroupDynamicsGraphProps> = ({
  data,
  triangleClickedHandler,
  offset,
  colorScheme,
  currentGroupDynamic
}) => {
  const rowCount = useMemo<number>(() => {
    const array: Array<number> = data.groupDynamics.map(
      (groupDynamic) => groupDynamic.position.row
    )
    return Math.max(...array)
  }, [data.groupDynamics])
  const columnCount = useMemo<number>(() => {
    const array: Array<number> = data.groupDynamics.map(
      (groupDynamic) => groupDynamic.position.column
    )
    return Math.max(...array)
  }, [data.groupDynamics])

  return (
    <Translate offset={offset}>
      {data.groupDynamics.map((groupDynamic) => (
        <GroupDynamicTriangle
          current={
            currentGroupDynamic ? currentGroupDynamic === groupDynamic : null
          }
          key={groupDynamic.name}
          groupDynamic={groupDynamic}
          dynamics={data.dynamics}
          rowCount={rowCount}
          columnCount={columnCount}
          onClickHandler={triangleClickedHandler}
          colorScheme={colorScheme}
        />
      ))}
    </Translate>
  )
}

export default GroupDynamicsGraph
