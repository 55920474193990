import NavItem from 'components/atoms/NavItem';
import { ROUTES } from 'components/templates/CustomRouter';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { HiHome, HiUserGroup } from 'react-icons/hi';

const Navigation = (): ReactElement => {
  const { t } = useTranslation('common');
  return (
    <nav
      className="z-20 flex-grow hidden w-full mt-2 bg-white lg:flex lg:items-center lg:w-auto lg:block lg:mt-0"
      id="nav-content"
    >
      <ul className="items-center flex-1 px-4 list-reset lg:flex md:px-0">
        <NavItem
          path={ROUTES.HOME.path}
          icon={<HiHome />}
          title={t('route:home')}
        />
        <NavItem
          path={ROUTES.EMPLOYEES.path}
          icon={<HiUserGroup />}
          title={t('route:employees')}
        />
      </ul>
    </nav>
  );
};

export default Navigation;
