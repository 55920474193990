import React, { Component, ErrorInfo, ReactElement, ReactNode } from 'react';
import Airbrake from 'services/airbrake';

type Props = { children: ReactElement };
type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    Airbrake.notify({
      error,
      params: { info },
    });
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return children;
  }
}

export default ErrorBoundary;
