import {
  LOG_IN_PENDING,
  LOG_IN_SUCCESS,
  LOGIN_FAILED,
  LOG_OUT,
  CHECK_LOGIN_STATUS,
  AuthStateActionTypes,
} from './types';

export const logInStart = (
  username: string,
  password: string
): AuthStateActionTypes => ({
  type: LOG_IN_PENDING,
  payload: {
    username,
    password,
  },
});

export const logInSuccess = (): AuthStateActionTypes => ({
  type: LOG_IN_SUCCESS,
});

export const logInFailure = (error: Error): AuthStateActionTypes => ({
  type: LOGIN_FAILED,
  payload: { error },
});

export const logOut = (): AuthStateActionTypes => ({
  type: LOG_OUT,
});

export const checkLoginStatus = (): AuthStateActionTypes => ({
  type: CHECK_LOGIN_STATUS,
});
