import React, { ReactElement } from 'react';

type Props = {
  size: 'large' | 'medium' | 'small';
  children: React.ReactNode;
};

const TextHeader = ({ size, children }: Props): ReactElement => {
  if (size === 'small') {
    return (
      <h5 className="text-xs font-bold text-gray-500 uppercase">{children}</h5>
    );
  }
  if (size === 'medium') {
    return <h3 className="text-3xl font-bold">{children}</h3>;
  }
  return <h2 className="text-3xl font-bold">{children}</h2>;
};

export default TextHeader;
