import React, { ReactElement, ReactNode } from 'react';

type LabelProps = {
  children: ReactNode;
  forInput: string;
};

const Label = ({ children, forInput }: LabelProps): ReactElement => {
  return <label htmlFor={forInput}>{children}</label>;
};

export default Label;
