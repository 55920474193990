import calcTriangleHeight from './calcTriangleHeight'

export const svgWidth = 760
export const svgHeight = 600
export const triangleWithSpaceWidth: number = 200
export const triangleWithSpaceHeight: number = calcTriangleHeight(
  triangleWithSpaceWidth
)
export const triangleWidth: number = 150
export const triangleHeight: number = calcTriangleHeight(triangleWidth)
