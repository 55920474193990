import { put, takeLatest } from 'redux-saga/effects';
import { Api, URL } from 'services/api';
import { fetchGraphSuccess, fetchGraphFailed } from './actions';
import { FETCH_GRAPHS_PENDING } from './types';

const fetchRequest = () => Api.get(URL.TRIANGLE_GRAPH).then((res) => res.data);
const fetchGuidance = () => Api.get(URL.REPORT).then(({ data }) => data);

function* fetchHandler() {
  try {
    const graphData = yield fetchRequest();
    const guidance = yield fetchGuidance();

    if (graphData && guidance) {
      yield put(fetchGraphSuccess({ ...graphData, guidance }));
    }
  } catch (err) {
    yield put(fetchGraphFailed(err));
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onFetchGraphs() {
  yield takeLatest(FETCH_GRAPHS_PENDING, fetchHandler);
}

export default [onFetchGraphs];
