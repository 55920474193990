import React, { useMemo } from 'react'
import RegularTriangle from '../molecules/RegularTriangle'
import Translate from '../molecules/Translate'
import { SVGPolygonProps } from '../../types'

interface TriangleWithSpaceProps extends Omit<SVGPolygonProps, 'height'> {
  innerLength: number
  innerHeight: number
  outerLength: number
  outerHeight: number
}

const TriangleWithSpace: React.FC<TriangleWithSpaceProps> = ({
  innerLength,
  innerHeight,
  outerLength,
  outerHeight,
  children,
  ...props
}) => {
  const vspace = useMemo(() => {
    return (outerHeight - innerHeight) / 2
  }, [innerHeight, outerHeight])

  const hspace = useMemo(() => {
    return (outerLength - innerLength) / 2
  }, [innerLength, outerLength])

  return (
    <Translate offset={{ x: hspace, y: vspace }} onClick={props.onClick}>
      <RegularTriangle length={innerLength} height={innerHeight} {...props} />
      {children}
    </Translate>
  )
}

export default TriangleWithSpace
