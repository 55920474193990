import React, { ReactElement } from 'react';
import TableHeader from 'components/atoms/TableHeader';

type LoadingTableProps = { headerContent: string[] };

const LoadingTable = ({ headerContent }: LoadingTableProps): ReactElement => {
  const renderCells = () =>
    headerContent.map((c, idx) => (
      <td
        key={`loading-cell-${c}`}
        className={`${
          idx === 0 ? 'w-6' : ''
        } border-t border-gray-200 border-dashed`}
      >
        <div className="flex items-center px-6 py-3 text-gray-700">
          <div
            className={`${
              idx === 0 ? 'w-4' : 'w-full'
            } h-4 bg-gray-400 rounded`}
          />
        </div>
      </td>
    ));

  const renderTableRows = () => {
    const arr = [];
    for (let index = 0; index < 10; index++) {
      arr.push(<tr key={`loading-${index}`}>{renderCells()}</tr>);
    }
    return arr;
  };

  return (
    <table className="w-full">
      <TableHeader content={headerContent} />
      <tbody className="animate-pulse">{renderTableRows()}</tbody>
    </table>
  );
};

export default LoadingTable;
