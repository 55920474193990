import React, { ReactElement } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import LoadingSpinner from '../LoadingSpinner';

type BulletListProps = { items?: string[] };

const BulletList = ({ items }: BulletListProps): ReactElement => {
  if (items) {
    const renderItems = () =>
      items.map((content) => (
        <li key={content} className="flex flex-row items-center pb-2 list-none">
          <FaArrowRight className="text-gray-400 absolute" size="16" />
          <p
            className="px-2 ml-4"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </li>
      ));

    return <ul>{renderItems()}</ul>;
  }
  return <LoadingSpinner />;
};

export default BulletList;
