import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { checkLoginStatus } from 'store/auth';
import { RootState } from 'store/configuration';
import TopMenu from 'components/organisms/TopMenu';

type Props = {
  children: ReactNode;
};

const BaseWithTopMenu = ({ children }: Props): ReactElement => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { loggedIn } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!loggedIn) dispatch(checkLoginStatus());
  });

  return (
    <>
      <Helmet>
        <title>
          {t('app:name')} | {t('app:adminPanel')}
        </title>
        <meta name="description" content={t('app:description')} />
      </Helmet>
      <TopMenu />
      <main className="container w-full pt-20 mx-auto">
        <div className="md:mt-8">{children}</div>
      </main>
    </>
  );
};

export default BaseWithTopMenu;
