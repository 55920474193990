import { takeLatest, put } from 'redux-saga/effects';
import { Api, URL, setHeader, AUTH_HEADER_KEY } from 'services/api';
import { clearState } from 'store/appState';
import {
  LOG_IN_PENDING,
  CHECK_LOGIN_STATUS,
  LOG_OUT,
  LoginAction,
} from './types';
import { logInSuccess, logInFailure } from './actions';

const TOKEN_KEY = 'token';

const logIn = (departmentId: string, password: string) => {
  const locale = 'da';
  return Api.post(`${URL.SESSION}`, {
    departmentId,
    password,
    locale,
  }).then((response) => response.data.token);
};

const invalidateToken = (token: string) => {
  // eslint-disable-next-line
  console.log(token);
  // return Api.delete(`${URL.SESSION}?token=${token}`);
};

const checkForToken = () => {
  return window.localStorage.getItem(TOKEN_KEY);
};

function* logInWithCredentials(action: LoginAction) {
  const {
    payload: { username, password },
  } = action;

  try {
    const response = yield logIn(username, password);
    yield setHeader('Authorization', response);
    yield window.localStorage.setItem(TOKEN_KEY, response);

    yield put(logInSuccess());
  } catch (error) {
    yield put(logInFailure(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onLogInStart() {
  yield takeLatest(LOG_IN_PENDING, logInWithCredentials);
}

function* checkAuthenticationStatus() {
  try {
    const sessionID = yield checkForToken();

    if (sessionID) {
      setHeader(AUTH_HEADER_KEY, sessionID);
      yield put(logInSuccess());
    }
  } catch (error) {
    yield put(logInFailure(error));

    yield window.location.replace('/login');
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onCheckAuthenticationStatusStart() {
  yield takeLatest(CHECK_LOGIN_STATUS, checkAuthenticationStatus);
}

function* logOutHandler() {
  try {
    const token = yield localStorage.getItem(TOKEN_KEY);
    yield invalidateToken(token);
    yield localStorage.removeItem(TOKEN_KEY);
    yield put(clearState());
  } catch (e) {
    throw new Error(e);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onUserLogOut() {
  yield takeLatest(LOG_OUT, logOutHandler);
}

export default [onLogInStart, onCheckAuthenticationStatusStart, onUserLogOut];
