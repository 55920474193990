import { normalize } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';
import { Api, URL } from 'services/api';
import {
  changeStatusFailed,
  changeStatusSuccess,
  fetchEmployeesSuccess,
  fetchEmplyeesFailed,
  updateEmployeeRolesFailed,
  updateEmployeeRolesSuccess,
} from './actions';
import EmployeeSchema from './schema';
import {
  ChangeStatusAction,
  CHANGE_STATUS,
  FETCH_EMPLOYEES_PENDING,
  UpdateRolesAction,
  UPDATE_ROLES,
} from './types';

const fetchEmployeesRequest = () =>
  Api.get(URL.EMPLOYEES).then((res) => res.data.employees);

function* fetchHandler() {
  try {
    const employees = yield fetchEmployeesRequest();

    const { entities, result } = yield normalize(employees, [EmployeeSchema]);

    yield put(fetchEmployeesSuccess(entities.employee, result));
  } catch (err) {
    yield put(fetchEmplyeesFailed(err));
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onFetchEmployees() {
  yield takeLatest(FETCH_EMPLOYEES_PENDING, fetchHandler);
}

const updateEmployeeStatusRequest = ({
  id,
  status,
}: {
  id: number;
  status: string;
}) =>
  Api.post(`${URL.EMPLOYEES}/${id}/${status}`).then(
    (res) => res.data.employees
  );

function* employeeChangeStatusHandler(action: ChangeStatusAction) {
  try {
    const { id, status } = action.payload;
    const response = yield updateEmployeeStatusRequest({ id, status });

    const { entities, result } = yield normalize(response, [EmployeeSchema]);

    yield put(changeStatusSuccess(entities.employee, result));
  } catch (error) {
    yield put(changeStatusFailed(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onEmployeeChangeStatus() {
  yield takeLatest(CHANGE_STATUS, employeeChangeStatusHandler);
}

function* updateEmployeeRolesHandler({
  payload: { id, roles },
}: UpdateRolesAction) {
  try {
    const res = yield Api.post(`${URL.EMPLOYEES}/${id}/update_roles`, {
      roles,
    }).then(({ data }) => data);
    const { entities, result } = yield normalize(res.employees, [
      EmployeeSchema,
    ]);
    yield put(updateEmployeeRolesSuccess(entities.employee, result));
  } catch (error) {
    yield put(updateEmployeeRolesFailed(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onUpdateEmployeeRoles() {
  yield takeLatest(UPDATE_ROLES, updateEmployeeRolesHandler);
}

export default [
  onFetchEmployees,
  onEmployeeChangeStatus,
  onUpdateEmployeeRoles,
];
