import React, { ReactElement } from 'react';
import Navigation from 'components/molecules/Navigation';
import UserDropdown from 'components/organisms/UserDropdown';
import { useTranslation } from 'react-i18next';

const TopMenu = (): ReactElement => {
  const [t] = useTranslation();
  return (
    <header id="header" className="fixed top-0 z-10 w-full bg-white shadow">
      <div className="container flex flex-wrap items-center w-full pt-3 pb-3 mx-auto mt-0 md:pb-0">
        <div className="w-1/2 pl-2 md:pl-0">
          <a
            className="flex flex-col text-base font-bold text-gray-900 no-underline xl:text-xl hover:no-underline"
            href="/"
          >
            {t('app:name')}
            <span className="text-sm font-normal text-gray-600 xl:text-lg">
              {t('app:adminPanel')}
            </span>
          </a>
        </div>
        <div className="w-1/2 pr-0">
          <div className="relative flex inline-block float-right">
            <UserDropdown />
          </div>
        </div>
        <div className="z-20 flex-grow hidden w-full mt-2 bg-white lg:flex lg:items-center lg:w-auto lg:block lg:mt-0">
          <Navigation />
        </div>
      </div>
    </header>
  );
};

export default TopMenu;
