const STATISTICS_BASE = 'STATISTICS:: ';

export interface Statisctic {
  id: string;
  name: string;
  subtitle: string;
  value: number;
  prevValue: number;
  change?: number;
  type: string;
  tendency: 1 | 0 | -1;
}

export type StatisticEntities = {
  [key: string]: Statisctic;
};

/**
 * State object types
 */
export interface StaticsState {
  loading: boolean;
  error?: Error;
  entities?: StatisticEntities;
  result?: string[];
}

/**
 * Action types
 */
export const FETCH_STATISTICS = `${STATISTICS_BASE}Fetching`;
export const FETCH_STATISTICS_SUCCESS = `${STATISTICS_BASE}Fetch Succeeded`;
export const FETCH_STATISTICS_FAILED = `${STATISTICS_BASE}Fetch Failed`;

export type FetchAction = {
  type: typeof FETCH_STATISTICS;
  payload?: undefined;
};

export type FetchSuccessAction = {
  type: typeof FETCH_STATISTICS_SUCCESS;
  payload: {
    entities: StatisticEntities;
    result: [];
    error?: undefined;
  };
};

export type FetchFailedAction = {
  type: typeof FETCH_STATISTICS_FAILED;
  payload: {
    error?: Error;
  };
};

export type StatiscsActions =
  | FetchAction
  | FetchSuccessAction
  | FetchFailedAction;
