import React, { ReactElement, ReactNode } from 'react';
import TextHeader from 'components/atoms/TextHeader';
import Card from 'components/atoms/Card';
import { Statisctic } from 'store/statistics';
import TendencyIndicator from 'components/atoms/TendencyIndicator';

interface Props extends Statisctic {
  children?: ReactNode;
  icon?: ReactElement;
  color?: string;
}

const MetricCard = ({
  name = 'Metric',
  subtitle,
  icon,
  color = 'green',
  value,
  children,
  prevValue,
  tendency,
}: Props): ReactElement => {
  if (children) {
    return (
      <Card>
        <div className="flex flex-row items-center">
          <div className="flex flex-col flex-wrap w-full p-6">
            <div className="flex flex-wrap">
              <div className="relative flex-1 flex-grow w-full max-w-full pr-4">
                {children}
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className="flex flex-row items-center">
        <div className="flex flex-col flex-wrap w-full p-6">
          <div className="flex flex-wrap">
            <div className="relative flex-1 flex-grow w-full max-w-full pr-4">
              <TextHeader size="small">{name}</TextHeader>
              <TextHeader size="medium">{value}</TextHeader>
            </div>
            <div className="relative self-end flex-initial w-auto pl-4">
              {icon && (
                <div
                  className={`inline-flex items-center justify-center w-12 h-12 p-3 text-center text-white bg-${color}-500 rounded-full shadow-lg`}
                >
                  {icon}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center mt-4 text-sm text-gray-500">
            <span
              className={`mr-2 flex flex-row items-center ${
                tendency >= 0 ? 'text-green-500' : 'text-red-400'
              }`}
            >
              <TendencyIndicator direction={tendency} />
              {prevValue}
            </span>
            <span className="whitespace-no-wrap">{subtitle}</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MetricCard;
