import React, { ReactElement, ReactNode } from 'react';
import TextHeader from 'components/atoms/TextHeader';
import Card from 'components/atoms/Card';

type Props = { title?: string; children?: ReactNode };

const GraphCard = ({
  title = 'Graph',
  children = 'graph',
}: Props): ReactElement => {
  return (
    <Card>
      <div className="p-3 border-b">
        <TextHeader size="small">{title}</TextHeader>
      </div>
      <div className="p5">{children}</div>
    </Card>
  );
};

export default GraphCard;
