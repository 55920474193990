import React, { useMemo } from 'react'
import { Coordinate, SVGPolygonProps } from '../../types'
import Polygon from '../atoms/Polygon'

interface TriangleProps extends SVGPolygonProps {
  top: Coordinate
  left: Coordinate
  right: Coordinate
  fill?: string
}

const Triangle: React.FC<TriangleProps> = ({ top, left, right, ...props }) => {
  const points = useMemo<Array<Coordinate>>(() => {
    return [top, left, right]
  }, [top, left, right])

  return <Polygon points={points} {...props} />
}

export default Triangle
