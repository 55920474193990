const AUTH_BASE = 'AUTH:: ';

/**
 * State object types
 */
export interface AuthState {
  loading: boolean;
  token?: string;
  loggedIn?: boolean;
  error?: Error;
}

/**
 * Action types
 */
export const LOG_IN_PENDING = `${AUTH_BASE}LOG IN PENDING`;
export const LOG_IN_SUCCESS = `${AUTH_BASE}LOG IN SUCCEEDED`;
export const LOGIN_FAILED = `${AUTH_BASE}LOG IN FAILED`;
export const LOG_OUT = `${AUTH_BASE}LOG OUT`;
export const CHECK_LOGIN_STATUS = `${AUTH_BASE}CHECK LOGIN STATUS`;

export interface LoginAction {
  type: typeof LOG_IN_PENDING;
  payload: {
    username: string;
    password: string;
    error?: undefined;
  };
}

export interface LoginSuccessAction {
  type: typeof LOG_IN_SUCCESS;
  payload?: {
    error?: undefined;
  };
}

export interface LoginFailedAction {
  type: typeof LOGIN_FAILED;
  payload: {
    error: Error;
  };
}

export type AuthStateActionTypes =
  | LoginAction
  | LoginSuccessAction
  | LoginFailedAction;
