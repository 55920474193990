import React, { useMemo } from 'react'
import { Coordinate, SVGPolygonProps } from '../../types'
import Triangle from './Triangle'

interface RegularTriangleProps extends SVGPolygonProps {
  height: number
  length: number
}

const RegularTriangle: React.FC<RegularTriangleProps> = ({
  height,
  length,
  ...props
}) => {
  const top = useMemo<Coordinate>(() => {
    return {
      x: length / 2,
      y: 0
    }
  }, [length])

  const left = useMemo<Coordinate>(() => {
    return {
      x: 0,
      y: height
    }
  }, [height])

  const right = useMemo<Coordinate>(() => {
    return {
      x: length,
      y: height
    }
  }, [length, height])

  return <Triangle top={top} left={left} right={right} {...props} />
}

export default RegularTriangle
