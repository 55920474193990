import {
  AuthStateActionTypes,
  AuthState,
  LOG_IN_PENDING,
  LOG_IN_SUCCESS,
  LOGIN_FAILED,
  CHECK_LOGIN_STATUS,
  LOG_OUT,
} from './types';

const initialState: AuthState = {
  loading: false,
  loggedIn: false,
  token: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: AuthStateActionTypes
): AuthState => {
  switch (action.type) {
    case CHECK_LOGIN_STATUS:
    case LOG_IN_PENDING:
      return {
        ...state,
        loading: true,
      };
    case LOG_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        error: undefined,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        error: action.payload?.error,
        loading: false,
      };

    case LOG_OUT:
      return initialState;
  }
  return state;
};

export default reducer;
