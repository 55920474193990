import { Reducer } from 'redux';
import {
  GraphState,
  GraphActions,
  FETCH_GRAPHS_PENDING,
  FETCH_GRAPHS_FAILED,
  FETCH_GRAPHS_SUCCESS,
} from './types';

const initialState: GraphState = {
  loading: false,
  error: undefined,
  data: undefined,
};

const graphReducer: Reducer<GraphState, GraphActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_GRAPHS_PENDING:
      return {
        ...state,
        loading: true,
        error: action.payload.error,
      };
    case FETCH_GRAPHS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case FETCH_GRAPHS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
  }

  return state;
};

export default graphReducer;
