import React, { useCallback, useMemo } from 'react'
import Translate from '../molecules/Translate'
import TriangleWithSpace from './TriangleWithSpace'
import {
  triangleWithSpaceWidth,
  triangleWithSpaceHeight,
  triangleWidth,
  triangleHeight
} from '../../utils/measurements'
import { Dynamic, GroupDynamic, ColorScheme } from '../../types'
import Text from '../atoms/Text'
import TendencyChange from '../molecules/TendencyChange'

interface Props {
  groupDynamic: GroupDynamic
  dynamics: Record<string, Dynamic>
  rowCount?: number
  columnCount?: number
  onClickHandler?: (groupDynamic: GroupDynamic) => any
  colorScheme?: ColorScheme
  current: boolean | null
}

const GroupDynamicTriangle: React.FC<Props> = ({
  groupDynamic,
  dynamics,
  rowCount = 0,
  columnCount = 0,
  onClickHandler,
  colorScheme,
  current
}) => {
  const calculateBackgroundColor = useCallback(
    (color: string) => {
      if (colorScheme && colorScheme[color]) {
        return colorScheme[color]
      }
      return color
    },
    [colorScheme]
  )

  const handleClick = useCallback(() => {
    if (onClickHandler) {
      onClickHandler(groupDynamic)
    }

    return undefined
  }, [onClickHandler, groupDynamic])

  const opacity = useMemo<number>(() => {
    if (current === null) {
      return 1
    }
    if (current) {
      return 1
    }

    return 0.3
  }, [current])

  return (
    <Translate
      offset={{
        y: groupDynamic.position.row * triangleWithSpaceHeight,
        x: groupDynamic.position.column * triangleWithSpaceWidth
      }}
    >
      <Text
        textAnchor='middle'
        x={triangleWithSpaceWidth / 2}
        y={5}
        opacity={opacity}
      >
        {dynamics[groupDynamic.top].name}
      </Text>
      <TriangleWithSpace
        innerLength={triangleWidth}
        innerHeight={triangleHeight}
        outerLength={triangleWithSpaceWidth}
        outerHeight={triangleWithSpaceHeight}
        fill={
          groupDynamic.color
            ? calculateBackgroundColor(groupDynamic.color)
            : 'black'
        }
        opacity={opacity}
        stroke='black'
        strokeWidth={0}
        strokeLinejoin='round'
        strokeLinecap='square'
        cursor='pointer'
        onClick={handleClick}
      >
        {groupDynamic.name ? (
          <Text
            useHtml
            clickable
            height={triangleWithSpaceHeight / 4}
            width={triangleWithSpaceWidth / 2 - 6}
            x={triangleWithSpaceWidth / 8 + 3}
            y={triangleHeight / 2 + 5}
            opacity={opacity}
          >
            {groupDynamic.name}
          </Text>
        ) : null}
        {groupDynamic.score && groupDynamic.prevScore && (
          <Text
            useHtml
            clickable
            height={triangleWithSpaceHeight / 8}
            width={triangleWithSpaceWidth / 2 - 6}
            x={triangleWithSpaceWidth / 8 + 3}
            y={triangleHeight - triangleHeight / 8 - 5}
            opacity={opacity}
            fontWeight='normal'
          >
            {groupDynamic.score}
            <TendencyChange
              score={groupDynamic.prevScore}
              tendency={groupDynamic.tendency}
            />
          </Text>
        )}
      </TriangleWithSpace>
      {groupDynamic.position.row === rowCount || current ? (
        <Text
          textAnchor='middle'
          y={triangleWithSpaceHeight + 5}
          opacity={opacity}
        >
          {dynamics[groupDynamic.left].name}
        </Text>
      ) : null}
      {groupDynamic.position.column === columnCount || current ? (
        <Text
          textAnchor='middle'
          x={triangleWithSpaceWidth}
          y={triangleWithSpaceHeight + 5}
          opacity={opacity}
        >
          {dynamics[groupDynamic.right].name}
        </Text>
      ) : null}
    </Translate>
  )
}

export default GroupDynamicTriangle
