import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HiDotsHorizontal } from 'react-icons/hi';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {
  Role,
  changeEmployeeStatus,
  updateEmployeeRolesPending,
} from 'store/employees';
import Badge from 'components/atoms/Badge';
import TableDropdown from 'components/organisms/TableDropDown';
import { RootState } from 'store/configuration';
import { RolesState } from 'store/roles';

type SelectOption = {
  value: string;
  label?: string;
};

type EmployeeTableRowProps = {
  id: number;
  name: string;
  employeeRoles: Role[];
  active: boolean;
};

const EmployeeTableRow = ({
  id,
  name,
  employeeRoles,
  active,
}: EmployeeTableRowProps): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { entities, result }: RolesState = useSelector(
    (state: RootState) => state.roles
  );
  const rowRef = useRef<HTMLTableRowElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);
  const [editActive, setEditActive] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { styles, attributes } = usePopper(
    btnRef.current,
    dropdownRef.current,
    {
      placement: 'left-start',
    }
  );
  const [selectedRoles, setSelectedRoles] = useState<SelectOption[]>([]);
  const [dirty, setDirty] = useState(false);

  const saveRoles = useCallback(() => {
    const roles = selectedRoles.map(({ value }) => value);
    return dispatch(updateEmployeeRolesPending(id, roles));
  }, [id, dispatch, selectedRoles]);

  // @ts-ignore
  const selectOnChangeHandler = (res) => {
    setSelectedRoles(res);
    return setDirty(true);
  };

  const toggleDropdown = () => {
    return setDropdownOpen(!dropdownOpen);
  };

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setDropdownOpen(false);
        if (editActive) setEditActive(false);
      }
    },
    [editActive]
  );

  const clickListener = useCallback(
    (e) => {
      if (!rowRef.current?.contains(e?.target)) {
        setDropdownOpen(false);
        if (editActive) setEditActive(false);
      }
    },
    [editActive]
  );

  const onDeactivateClickHandler = () =>
    dispatch(changeEmployeeStatus(id, active ? 'deactivate' : 'activate'));

  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);

    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [clickListener, escapeListener]);

  useEffect(() => {
    if (employeeRoles && entities) {
      const selectedRoleIds = employeeRoles
        .filter(({ selected }) => selected > 0)
        .map((r) => ({
          label: entities[r.id]?.name,
          value: r.id,
        }));
      setSelectedRoles(selectedRoleIds);
    }
  }, [employeeRoles, entities]);

  useEffect(() => {
    if (selectedRoles && dirty) {
      saveRoles();
      setDirty(false);
    }
  }, [dirty, setDirty, saveRoles, selectedRoles]);

  const rolesOpt = result?.map((el) => {
    return {
      value: el,
      label: entities[el].name,
    };
  });

  const renderRoleBadges = () => {
    if (rolesOpt) {
      return selectedRoles?.map(({ label }) => {
        return <Badge key={label} text={label} />;
      });
    }
    return <span>Loading...</span>;
  };

  return (
    <tr key={id} ref={rowRef}>
      <td className="w-6 border-t border-gray-200 border-dashed">
        <span className="flex items-center px-4 py-3 text-gray-700">{id}</span>
      </td>
      <td className="w-4/6 border-t border-gray-200 border-dashed">
        <span className="flex items-center px-4 py-3 text-gray-700">
          {name}
        </span>
      </td>
      <td className="w-2/6 border-t border-gray-200 border-dashed">
        <span className="flex items-center px-4 py-3 text-gray-700">
          {editActive && (
            <Select
              className="w-full"
              options={rolesOpt}
              value={selectedRoles}
              isMulti
              onChange={selectOnChangeHandler}
            />
          )}
          {!editActive && renderRoleBadges()}
        </span>
      </td>
      <td className="w-2/6 border-t border-gray-200 border-dashed">
        <span className="flex items-center px-4 py-3 text-gray-700">
          {active ? (
            <Badge text={t('common:aktiv')} color="green" />
          ) : (
            <Badge text={t('common:unactive')} color="red" />
          )}
        </span>
      </td>
      <td className="w-6 border-t border-gray-200 border-dashed">
        <div
          ref={btnRef}
          className="relative flex items-center px-4 py-3 text-gray-700 cursor-pointer"
          role="button"
          aria-label={t('common:actions')}
          onClick={toggleDropdown}
          tabIndex={0}
          onKeyPress={toggleDropdown}
        >
          <HiDotsHorizontal size={25} />
          <div ref={dropdownRef}>
            <TableDropdown
              open={dropdownOpen}
              employeeActive={active}
              style={styles.popper}
              editRowHandler={() => setEditActive(true)}
              statusChangeHandler={onDeactivateClickHandler}
              {...attributes.popper}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default EmployeeTableRow;
