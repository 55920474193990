import React, { ReactElement, ReactNode, MouseEvent } from 'react';

interface ButtonProps {
  color?: string;
  size?: 'lg' | 'sm';
  value: string;
  children: ReactNode;
  tabIndex?: number;
  onClick?: (event: MouseEvent) => void;
  type?: 'submit' | 'reset' | 'button';
}

const buttonSize = {
  lg: 'px-4 py-2',
  sm: 'px-2 py-1 text-xs',
};

const Button: React.FC<ButtonProps> = ({
  color = 'blue',
  size = 'lg',
  value,
  children,
  tabIndex = 0,
  onClick,
  type = 'button',
}): ReactElement => {
  return (
    <button
      className={`${buttonSize[size]} capitalize font-bold text-white bg-${color}-500 rounded hover:bg-${color}-700 focus:outline-none focus:shadow-outline`}
      // eslint-disable-next-line react/button-has-type
      type={type}
      value={value}
      tabIndex={tabIndex}
      aria-label={value}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
