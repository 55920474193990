import React from 'react'
import TendencyIcon from '../atoms/TendencyIcon'

type Props = {
  score: number
  tendency: number
}
const TendencyChange = ({ score, tendency }: Props) => {
  return (
    <span
      style={{
        marginLeft: '5px',
        color: '#000'
      }}
    >
      (
      <TendencyIcon color={'#000'} tendency={tendency} />
      {score})
    </span>
  )
}

export default TendencyChange
