import BaseWithTopMenu from 'components/templates/BaseWithTopMenu';
import React, { ReactElement } from 'react';
import EmployeeTableCard from 'components/organisms/EmployeeTableCard';

const Employees = (): ReactElement => (
  <BaseWithTopMenu>
    <section className="flex flex-row flex-wrap flex-grow mt-2">
      <div className="w-full p-3">
        <EmployeeTableCard />
      </div>
    </section>
  </BaseWithTopMenu>
);

export default Employees;
