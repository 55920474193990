import {
  FETCH_ROLES_PENDING,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILED,
  FetchAction,
  FetchFailedAction,
  FetchSuccessAction,
  IRolesEntities,
} from './types';

export const fetchRoles = (): FetchAction => ({
  type: FETCH_ROLES_PENDING,
});

export const fetchRolesSuccess = (
  entities: IRolesEntities,
  result: []
): FetchSuccessAction => ({
  type: FETCH_ROLES_SUCCESS,
  payload: {
    entities,
    result,
  },
});

export const fetchRolesFailed = (error: Error): FetchFailedAction => ({
  type: FETCH_ROLES_FAILED,
  payload: { error },
});
