import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { TriangleGraph } from '@prograsdk/dma-report-components';
import { RootState } from 'store/configuration';
import { GraphState, fetchGraphData } from 'store/graphs';
import { fetchStatistics } from 'store/statistics';
import BaseWithTopMenu from 'components/templates/BaseWithTopMenu';
import GraphCard from 'components/molecules/GraphCard';
import LoadingSpinner from 'components/atoms/LoadingSpinner';
import ReportDynamicsCol from 'components/organisms/ReportDynamicsCol/ReportDynamicsCol';
import TextHeader from 'components/atoms/TextHeader';
import BulletList from 'components/atoms/BulletList';
import HomeViewMetricCards from 'components/organisms/HomeViewMetricCards';

const Home = (): ReactElement => {
  const [t] = useTranslation();
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const { data, loading }: GraphState = useSelector(
    (state: RootState) => state.graphs
  );
  const { entities } = useSelector((state: RootState) => state.statics);

  const [activeDynamics, setActiveDynamics] = useState<string[] | null>([]);

  const updateActiveDynamics = (dynamic: string[] | null) =>
    setActiveDynamics(dynamic);

  useEffect(() => {
    if ((!data || !entities) && firstRender.current) {
      dispatch(fetchGraphData());
      dispatch(fetchStatistics());
      firstRender.current = false;
    }
  });

  const guidanceArray = useMemo(() => {
    if (data) {
      return data.guidance?.map((g) => g.guidance);
    }
    return [];
  }, [data]);

  return (
    <BaseWithTopMenu>
      <section className="flex flex-wrap">
        <HomeViewMetricCards />
      </section>
      <section className="flex flex-row flex-wrap flex-grow mt-2">
        <div className="w-full p-3 ">
          <GraphCard title="Motivational Drivers">
            <div className="flex w-full divide-x divide-gray-400">
              <div className="md:w-3/4">
                {!data || loading ? (
                  <LoadingSpinner />
                ) : (
                  <TriangleGraph
                    data={data}
                    returnCurrentGroupDynamicsCallback={updateActiveDynamics}
                    colorScheme={{
                      red: '#E53E3E',
                      green: '#48BB78',
                      yellow: '#FAF089',
                    }}
                  />
                )}
              </div>
              <div className="md:w-1/4 divide-y divide-gray-400">
                <ReportDynamicsCol activeDynamics={activeDynamics} />
              </div>
            </div>
            {!isEmpty(guidanceArray) && (
              <div className="w-full p-3 border-t border-gray-400">
                <TextHeader size="small">
                  {t('common:generalGuidance')}
                </TextHeader>
                <div className="w-full p-3">
                  <BulletList items={guidanceArray} />
                </div>
              </div>
            )}
          </GraphCard>
        </div>
      </section>
    </BaseWithTopMenu>
  );
};

export default Home;
