import React, { ReactElement } from 'react';

type BadgeProps = { text?: string; color?: string };

const Badge = ({ text = 'Text', color = 'gray' }: BadgeProps): ReactElement => {
  return (
    <span
      className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-${color}-600 bg-${color}-200 uppercase last:mr-0 mr-1`}
    >
      {text}
    </span>
  );
};

export default Badge;
