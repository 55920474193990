import {
  combineReducers,
  createStore,
  applyMiddleware,
} from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { CLEAR_STATE } from './appState';
import authReducer, { sagas as authSagas } from './auth';
import employeeReducer, { sagas as employeeSagas } from './employees';
import graphReducer, { sagas as graphSagas } from './graphs';
import rolesReducer, { sagas as rolesSagas } from './roles';
import statisticsReducer, { sagas as statisticsSagas } from './statistics';

const sagaMiddleware = createSagaMiddleware();

export const reducers = combineReducers({
  auth: authReducer,
  employees: employeeReducer,
  graphs: graphReducer,
  roles: rolesReducer,
  statics: statisticsReducer,
});

// @ts-ignore
const rootReducer = (state, action) => {
  switch (action.type) {
    case CLEAR_STATE:
      return reducers(undefined, action);
    default:
      return reducers(state, action);
  }
};

const enhancers = composeWithDevTools(applyMiddleware(sagaMiddleware));

export const store = createStore(rootReducer, enhancers);

export type RootState = ReturnType<typeof rootReducer>;

function* rootSaga() {
  yield all(
    [
      ...authSagas,
      ...employeeSagas,
      ...rolesSagas,
      ...graphSagas,
      ...statisticsSagas,
    ].map(fork)
  );
}

sagaMiddleware.run(rootSaga);
