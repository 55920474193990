import React, { ReactElement, useEffect, useRef } from 'react';
import GraphCard from 'components/molecules/GraphCard';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/configuration';
import { EmployeeState, fetchEmployees } from 'store/employees';
import { fetchRoles } from 'store/roles';
import TableHeader from 'components/atoms/TableHeader';
import LoadingTable from '../LoadingTable';
import EmployeeTableRow from '../EmployeeTableRow';

const EmployeeTableCard = (): ReactElement => {
  const { t } = useTranslation();
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const { entities, result, loading }: EmployeeState = useSelector(
    (state: RootState) => state.employees
  );

  useEffect(() => {
    if (!result && firstRender.current) {
      dispatch(fetchEmployees());
      dispatch(fetchRoles());
      firstRender.current = false;
    }
  });

  const TABLE_HEADER_CONTENT = [
    t('common:userId'),
    t('common:name'),
    t('roles'),
    t('status'),
    '',
  ];

  const renderEmployeeRows = () =>
    result?.map((id) => {
      if (entities) {
        const current = entities[id];

        return (
          <EmployeeTableRow
            key={id}
            {...current}
            employeeRoles={current.roles}
          />
        );
      }
      return null;
    });

  if (!result || loading) {
    return (
      <GraphCard title={t('common:employees')}>
        <LoadingTable headerContent={TABLE_HEADER_CONTENT} />
      </GraphCard>
    );
  }
  return (
    <GraphCard title={t('common:employees')}>
      <table className="w-full">
        <TableHeader content={TABLE_HEADER_CONTENT} />
        <tbody>{renderEmployeeRows()}</tbody>
      </table>
    </GraphCard>
  );
};

export default EmployeeTableCard;
