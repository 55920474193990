import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CustomRouter from 'components/templates/CustomRouter';
import ErrorBoundary from 'components/templates/ErrorBoundary';
import { store } from './store/configuration';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'services/i18n';

const App = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <CustomRouter />
      </ErrorBoundary>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
