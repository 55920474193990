import React, { useMemo } from 'react'
import { Coordinate, SVGPolygonProps } from '../../types'

interface PolygonProps extends SVGPolygonProps {
  points: Array<Coordinate>
}

const Polygon: React.FC<PolygonProps> = ({ points, ...props }) => {
  const formattedPoints = useMemo<string>(() => {
    return points.map((point) => `${point.x},${point.y}`).join(' ')
  }, [points])

  return <polygon points={formattedPoints} {...props} />
}

export default Polygon
