import { Notifier } from '@airbrake/browser';
// const projectId = process.env.REACT_APP_AIRBRAKE_PROJECT_ID;
// const projectKey = process.env.REACT_APP_AIRBRAKE_PROJECT_KEY;

const airbrake = new Notifier({
  projectId: 297283,
  projectKey: '0416530b2c9edb7d8c4bca6fdc92722b',
});

export default airbrake;
