import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Base from 'components/templates/Base';

const PageNotFound = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <Base>
      <h1>{t('pageNotFound')}</h1>
    </Base>
  );
};

export default PageNotFound;
