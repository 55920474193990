import { ReportData } from '@prograsdk/dma-report-components/dist/types';

const GRAPHS_BASE = 'GRAPHS:: ';

export interface IGraphEntities {
  [key: string]: ReportData;
}

export interface Data extends ReportData {
  // the data should be able to contain any type
  // eslint-disable-next-line
  guidance?: any[];
}

/**
 * State object types
 */
export interface GraphState {
  loading: boolean;
  error?: Error;
  data?: Data;
}

/**
 * Action types
 */
export const FETCH_GRAPHS_PENDING = `${GRAPHS_BASE}Fetching`;
export const FETCH_GRAPHS_SUCCESS = `${GRAPHS_BASE}Fetch Succeeded`;
export const FETCH_GRAPHS_FAILED = `${GRAPHS_BASE}Fetch Failed`;

export interface FetchAction {
  type: typeof FETCH_GRAPHS_PENDING;
  payload: {
    error: undefined;
  };
}

export interface FetchSuccessAction {
  type: typeof FETCH_GRAPHS_SUCCESS;
  payload: {
    data: ReportData;
    error?: undefined;
  };
}

export interface FetchFailedAction {
  type: typeof FETCH_GRAPHS_FAILED;
  payload: {
    error: Error;
  };
}

export type GraphActions = FetchAction | FetchSuccessAction | FetchFailedAction;
