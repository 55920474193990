import React, { ReactElement } from 'react';

type TableHeaderProps = { content: string[] };

const TableHeader = ({ content }: TableHeaderProps): ReactElement => {
  const renderHeaderCell = () =>
    content.map((c) => {
      return (
        <th
          key={c}
          className="sticky top-0 px-4 py-2 text-xs font-bold tracking-wider text-gray-600 uppercase bg-gray-100 border-b border-gray-200"
        >
          {c}
        </th>
      );
    });

  return (
    <thead>
      <tr className="text-left">{renderHeaderCell()}</tr>
    </thead>
  );
};

export default TableHeader;
