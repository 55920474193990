const ACTION_PREFIX = 'Root State::';

export const CLEAR_STATE = `${ACTION_PREFIX} Clear State`;

export const SET_LOADING_STATE = `${ACTION_PREFIX} Set loading state`;

export interface ClearStateAction {
  type: typeof CLEAR_STATE;
}

export interface SetLoadingStateAction {
  type: typeof SET_LOADING_STATE;
  payload: boolean;
}
