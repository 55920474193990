import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiUser } from 'react-icons/hi';

type Props = {
  imageSrc?: string;
};

const UserAvatar = ({ imageSrc }: Props): ReactElement => {
  const [t] = useTranslation();
  const [loaded, setLoaded] = useState(false);

  const renderAvatar = () => {
    if (imageSrc) {
      return (
        <img
          src={imageSrc}
          alt={t('common:user')}
          className="rounded-full"
          onLoad={() => setLoaded(true)}
        />
      );
    }

    return <HiUser size="100%" />;
  };

  return (
    <div
      className={`flex items-center justify-center w-10 h-10 mr-4 text-white bg-gray-400 rounded-full ${
        imageSrc && !loaded ? 'animate-pulse' : ''
      }`}
    >
      {renderAvatar()}
    </div>
  );
};

export default UserAvatar;
