import React, { ReactElement } from 'react';

const LoadingMetricCard = (): ReactElement => {
  return (
    <div className="w-full max-w-sm p-3 mx-auto bg-white border rounded shadow-lg">
      <div className="flex items-center animate-pulse space-x-4">
        <div className="flex-1 py-1 space-y-4">
          <div className="w-3/6 h-4 bg-gray-400 rounded" />
          <div className="space-y-2">
            <div className="w-1/6 h-12 bg-gray-400 rounded" />
            <div className="w-2/6 h-2 bg-gray-400 rounded" />
          </div>
        </div>
        <div className="w-12 h-12 bg-gray-400 rounded-full" />
      </div>
    </div>
  );
};

export default LoadingMetricCard;
