import React, { ReactElement, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

type BaseProps = { children: ReactNode };

const Base = ({ children }: BaseProps): ReactElement => {
  const [t] = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('app:name')} | {t('app:adminPanel')}
        </title>
        <meta name="description" content={t('app:description')} />
      </Helmet>

      <main className="container flex items-center content-center justify-center h-screen mx-auto">
        {children}
      </main>
    </>
  );
};

export default Base;
