import React, { ReactElement } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from 'components/templates/PrivateRoute';
import Login from 'components/views/Login';
import Home from 'components/views/Home';
import PageNotFound from 'components/views/PageNotFound';
import Employees from 'components/views/Employees';
import ROUTES from './routes';

const CustomRouter = (): ReactElement => {
  const { HOME, LOGIN, NOT_FOUND, EMPLOYEES } = ROUTES;

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path={HOME.path}>
          <Home />
        </PrivateRoute>
        <PrivateRoute exact path={EMPLOYEES.path}>
          <Employees />
        </PrivateRoute>
        <Route exact path={LOGIN.path}>
          <Login />
        </Route>
        <Route path={NOT_FOUND.path}>
          <PageNotFound />
        </Route>
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default CustomRouter;
