import React, { ReactElement, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeStatusAction } from 'store/employees';

type TableDropdownProps = {
  style: CSSProperties;
  open: boolean;
  editRowHandler: () => void;
  statusChangeHandler: () => ChangeStatusAction;
  employeeActive: boolean;
};

const TableDropdown = ({
  style,
  open,
  editRowHandler,
  statusChangeHandler,
  employeeActive,
}: TableDropdownProps): ReactElement | null => {
  const { t } = useTranslation();
  const textColor = `${employeeActive ? 'red' : 'green'}`;

  if (open) {
    return (
      <div
        style={{ ...style }}
        className="absolute w-56 mt-2 shadow-lg rounded-md"
      >
        <div className="bg-white rounded-md shadow-xs">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <span
              className="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              role="menuitem"
              onClick={editRowHandler}
              tabIndex={0}
              onKeyPress={editRowHandler}
            >
              {t('common:editRoles')}
            </span>
            <span
              className={`block px-4 py-2 text-sm text-${textColor}-700 leading-5 hover:bg-${textColor}-100 hover:text-${textColor}-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 capitalize`}
              role="menuitem"
              onClick={statusChangeHandler}
              tabIndex={0}
              onKeyPress={statusChangeHandler}
            >
              {employeeActive ? t('common:deactivate') : t('common:activate')}
            </span>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default TableDropdown;
