import React, { ReactElement, ReactNode } from 'react';
import { HiOutlineX, HiOutlineBell } from 'react-icons/hi';

type AlertProps = {
  type?: 'info' | 'warning' | 'danger';
  showAlert: boolean;
  closeAlertHandler?: () => void;
  children: ReactNode;
};

const alertColor = {
  warning: 'orange',
  danger: 'red',
  info: 'gray',
  default: 'gray',
};

const Alert = ({
  type = 'info',
  showAlert,
  closeAlertHandler,
  children,
}: AlertProps): ReactElement | null => {
  if (showAlert) {
    return (
      <div
        className={`px-4 py-3 text-${alertColor[type]}-900 bg-${alertColor[type]}-100 border-t-4 border-${alertColor[type]}-500 rounded-b shadow-sm`}
        role="alert"
      >
        <div className="flex items-center justify-between">
          <div className="py-1">
            <HiOutlineBell />
          </div>
          <div className="py-1">{children}</div>
          {closeAlertHandler && (
            <div className="py-1">
              <HiOutlineX onClick={closeAlertHandler} role="button" />
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default Alert;
