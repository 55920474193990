import { ReportData } from '@prograsdk/dma-report-components/dist/types';
import {
  FetchAction,
  FetchSuccessAction,
  FETCH_GRAPHS_FAILED,
  FETCH_GRAPHS_PENDING,
  FETCH_GRAPHS_SUCCESS,
  FetchFailedAction,
} from './types';

export const fetchGraphData = (): FetchAction => ({
  type: FETCH_GRAPHS_PENDING,
  payload: {
    error: undefined,
  },
});

export const fetchGraphSuccess = (data: ReportData): FetchSuccessAction => ({
  type: FETCH_GRAPHS_SUCCESS,
  payload: {
    data,
  },
});

export const fetchGraphFailed = (error: Error): FetchFailedAction => ({
  type: FETCH_GRAPHS_FAILED,
  payload: { error },
});
