import React, { SVGProps, useMemo } from 'react'

interface TextProps extends SVGProps<SVGTextElement> {
  useHtml?: boolean
  clickable?: boolean
}

const Text: React.FC<TextProps> = ({
  children,
  useHtml = false,
  clickable = false,
  ...props
}) => {
  const fontSize = 12
  const shouldSplit = useMemo(
    () => (children ? children.toString().split(' ').length > 2 : false),
    [children]
  )

  const recalCulatedY = useMemo(() => {
    // if (shouldSplit && children && props.y) {
    //   const yValue: number = +props.y
    //   return yValue - (children?.toString().split(' ').length - 2) * fontSize
    // }
    return props.y
  }, [children, props.y])

  if (shouldSplit || useHtml) {
    return (
      <foreignObject
        width={props.width}
        height={props.height}
        x={props.x}
        y={recalCulatedY}
        opacity={props.opacity}
      >
        <p
          style={{
            margin: 0,
            padding: 0,
            fontWeight: 'bold',
            cursor: clickable ? 'pointer' : 'inherit',
            fontSize,
            lineHeight: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          {children}
        </p>
      </foreignObject>
    )
  }
  return <text {...props}>{children}</text>
}

export default Text
