import React, { ReactElement, ChangeEvent } from 'react';

type InputProps = {
  type?: string;
  id?: string;
  placeholder?: string;
  onValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

const Input = ({
  type,
  id,
  placeholder,
  onValueChange,
  value,
}: InputProps): ReactElement => (
  <input
    className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
    value={value}
    onChange={onValueChange}
    id={id}
    type={type}
    placeholder={placeholder}
    aria-label={placeholder}
  />
);

export default Input;
