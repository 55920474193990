import React, { ReactElement } from 'react';
import { FaArrowDown, FaArrowUp, FaCircle } from 'react-icons/fa';

interface Props {
  direction: number;
  className?: string;
  size?: string;
}

const TendencyIndicator = ({
  direction,
  size = '12',
  className = '',
}: Props): ReactElement => {
  if (direction === 1) {
    return (
      <FaArrowUp size={size} className={`${className} inline text-green-500`} />
    );
  }
  if (direction === -1) {
    return (
      <FaArrowDown size={size} className={`${className} inline text-red-500`} />
    );
  }

  return (
    <FaCircle size={size} className={`${className} inline  text-gray-500`} />
  );
};

export default TendencyIndicator;
