const EMPLOYEES_BASE = 'EMPLOYEES:: ';
export interface Role {
  id: string;
  selected: number;
}

export type Employee = {
  id: number;
  name: string;
  active: boolean;
  roles: Role[];
};

export interface IEmployeeEntities {
  [key: string]: Employee;
}
/**
 * State object types
 */
export interface EmployeeState {
  loading: boolean;
  error?: Error;
  entities?: IEmployeeEntities;
  result?: number[];
}

/**
 * Action types
 */
export const FETCH_EMPLOYEES_PENDING = `${EMPLOYEES_BASE}Fetching`;
export const FETCH_EMPLOYEES_SUCCESS = `${EMPLOYEES_BASE}Fetch Succeeded`;
export const FETCH_EMPLOYEES_FAILED = `${EMPLOYEES_BASE}Fetch Failed`;

export const CHANGE_STATUS = `${EMPLOYEES_BASE}Change employee status`;
export const CHANGE_STATUS_SUCCESS = `${EMPLOYEES_BASE}Status changed`;
export const CHANGE_STATUS_FAILED = `${EMPLOYEES_BASE}Status change failed`;

export const UPDATE_ROLES = `${EMPLOYEES_BASE}Update Employee Roles`;
export const UPDATE_ROLES_SUCCESS = `${EMPLOYEES_BASE}Roles Updated`;
export const UPDATE_ROLES_FAILED = `${EMPLOYEES_BASE}Update Roles Failed`;

export interface FetchAction {
  type: typeof FETCH_EMPLOYEES_PENDING;
  payload?: undefined;
}

export interface FetchSuccessAction {
  type: typeof FETCH_EMPLOYEES_SUCCESS;
  payload: {
    entities: IEmployeeEntities;
    result: number[];
    error?: undefined;
  };
}

export interface FetchFailedAction {
  type: typeof FETCH_EMPLOYEES_FAILED;
  payload?: { error?: Error; entities?: undefined; result?: undefined };
}

export interface ChangeStatusAction {
  type: typeof CHANGE_STATUS;
  payload: {
    id: number;
    status: string;
    error?: Error;
    entities?: undefined;
    result?: undefined;
  };
}

export interface UpdateRolesAction {
  type: typeof UPDATE_ROLES;
  payload: {
    id: number;
    roles?: string[];
    error?: Error;
  };
}

export type EmployeeActions =
  | FetchAction
  | FetchSuccessAction
  | FetchFailedAction
  | ChangeStatusAction;
