import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { groupBy, maxBy } from 'lodash'

import GroupDynamicsGraph from './components/organisms/GroupDynamicsGraph'
import GraphWrapper from './components/templates/GraphWrapper'
import { ReportData, GroupDynamic, Coordinate, ColorScheme } from './types'
import {
  triangleWithSpaceWidth,
  triangleWithSpaceHeight,
  svgWidth,
  svgHeight
} from './utils/measurements'

interface TriangleGraphProps {
  data: ReportData
  triangleClickedHandler?: (groupDynamic: GroupDynamic) => any
  onBackHandler?: () => any
  groupDynamic?: GroupDynamic
  returnCurrentGroupDynamicsCallback?: (dynamicText: string[] | null) => any
  colorScheme?: ColorScheme
}

const getMatrixSize = (groupDynamics: GroupDynamic[]): Coordinate => {
  const largestGroup = (
    groups: Record<string, GroupDynamic[]>
  ): GroupDynamic[] | undefined => {
    return maxBy(Object.values(groups), (group) => group.length)
  }
  const rowGroups = groupBy(groupDynamics, (gp) => Math.ceil(gp.position.row))
  const columnGroups = groupBy(groupDynamics, (gp) =>
    Math.ceil(gp.position.column)
  )

  return {
    x: largestGroup(rowGroups)?.length || 0,
    y: largestGroup(columnGroups)?.length || 0
  }
}

export const TriangleGraph: React.FC<TriangleGraphProps> = ({
  data,
  triangleClickedHandler,
  groupDynamic,
  returnCurrentGroupDynamicsCallback,
  colorScheme
}) => {
  const [
    currentGroupDynamic,
    setCurrentGroupDynamic
  ] = useState<GroupDynamic | null>(null)
  const offset = useMemo<Coordinate>(() => {
    const matrix = getMatrixSize(data.groupDynamics)
    const result = {
      x: (svgWidth - matrix.x * triangleWithSpaceWidth) / 2,
      y: (svgHeight - matrix.y * triangleWithSpaceHeight) / 2
    }

    return result
  }, [])
  const handleTriangleClick = useCallback(
    (groupDynamic) => {
      if (currentGroupDynamic === groupDynamic) {
        return setCurrentGroupDynamic(null)
      }

      setCurrentGroupDynamic(groupDynamic)

      if (triangleClickedHandler) {
        triangleClickedHandler(groupDynamic)
      }
    },
    [currentGroupDynamic, setCurrentGroupDynamic, triangleClickedHandler]
  )

  const returnCurrentGroupDynamics = (
    dynamic: GroupDynamic | null,
    callback: (arg: string[]) => void
  ) => {
    if (dynamic) {
      const shownDynamics = [dynamic.top, dynamic.left, dynamic.right]
      return callback(shownDynamics)
    }
    return callback([])
  }

  useEffect(() => {
    if (groupDynamic) {
      setCurrentGroupDynamic(groupDynamic)
    }
  }, [setCurrentGroupDynamic, groupDynamic])

  useEffect(() => {
    if (returnCurrentGroupDynamicsCallback) {
      returnCurrentGroupDynamics(
        currentGroupDynamic,
        returnCurrentGroupDynamicsCallback
      )
    }
  }, [currentGroupDynamic])

  return (
    <GraphWrapper>
      <GroupDynamicsGraph
        data={data}
        triangleClickedHandler={handleTriangleClick}
        currentGroupDynamic={currentGroupDynamic}
        offset={offset}
        colorScheme={colorScheme}
      />
    </GraphWrapper>
  )
}
