import {
  ClearStateAction,
  CLEAR_STATE,
  SetLoadingStateAction,
  SET_LOADING_STATE,
} from './types';

export const clearState = (): ClearStateAction => ({
  type: CLEAR_STATE,
});

export const setLoadingState = (payload: boolean): SetLoadingStateAction => ({
  type: SET_LOADING_STATE,
  payload,
});
