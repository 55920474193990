import {
  FETCH_ROLES_FAILED,
  FETCH_ROLES_PENDING,
  FETCH_ROLES_SUCCESS,
  RolesActions,
  RolesState,
} from './types';

const initialState: RolesState = {
  loading: false,
  error: undefined,
  entities: {},
  result: undefined,
};

const reducer = (state = initialState, action: RolesActions): RolesState => {
  switch (action.type) {
    case FETCH_ROLES_PENDING:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case FETCH_ROLES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload?.error,
      };
  }
  return state;
};

export default reducer;
