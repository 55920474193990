import React, { useMemo } from 'react'
import { Coordinate } from '../../types'
import Group from '../atoms/Group'

interface Props {
  offset: Partial<Coordinate>
  onClick?: (event: any) => void
}

const Translate: React.FC<Props> = ({ offset, children, onClick }) => {
  const translate = useMemo<string>(() => {
    return `translate(${offset.x || 0}, ${offset.y || 0})`
  }, [offset.x, offset.y])

  return (
    <Group transform={translate} onClick={onClick}>
      {children}
    </Group>
  )
}

export default Translate
