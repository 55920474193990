const ROLES_BASE = 'ROLES:: ';

export interface Role {
  id: string;
  selected: number;
  name?: string;
}

export interface IRolesEntities {
  [key: string]: Role;
}

/**
 * State object types
 */
export interface RolesState {
  loading: boolean;
  error?: Error;
  entities: IRolesEntities;
  result?: string[];
}

/**
 * Action types
 */
export const FETCH_ROLES_PENDING = `${ROLES_BASE}Fetching`;
export const FETCH_ROLES_SUCCESS = `${ROLES_BASE}Fetch Succeeded`;
export const FETCH_ROLES_FAILED = `${ROLES_BASE}Fetch Failed`;

export interface FetchAction {
  type: typeof FETCH_ROLES_PENDING;
  payload?: {
    error: undefined;
  };
}

export interface FetchSuccessAction {
  type: typeof FETCH_ROLES_SUCCESS;
  payload: {
    entities: IRolesEntities;
    result: [];
    error?: undefined;
  };
}

export interface FetchFailedAction {
  type: typeof FETCH_ROLES_FAILED;
  payload: {
    error: Error;
  };
}

export type RolesActions = FetchAction | FetchSuccessAction | FetchFailedAction;
