import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configuration';
import LoadingSpinner from 'components/atoms/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import TendencyIndicator from 'components/atoms/TendencyIndicator';

interface Props {
  activeDynamics: string[] | null;
}

const ReportDynamicsCol = ({ activeDynamics }: Props): ReactElement => {
  const [t] = useTranslation();
  const { data, loading } = useSelector((state: RootState) => state.graphs);

  const renderDynamics = () => {
    if (data?.dynamics && !loading) {
      return Object.entries(data.dynamics).map((d) => {
        const [key, value] = d;
        const active = activeDynamics?.includes(key) ?? false;
        return (
          <div
            key={value.name}
            className={`flex flex-row items-center justify-center px-2 py-4 text-lg ${
              active ? 'bg-gray-200' : ''
            }`}
          >
            <div
              className={`w-4 h-4 shadow-lg rounded bg-${value.color}-400`}
            />
            <div className="flex flex-row justify-between w-full mx-2">
              <h2>{value.name}</h2>
              <div className="flex flex-row items-center nowrap">
                <p>{value.score}</p>
                <p className="text-sm ml-1">
                  (
                  <TendencyIndicator
                    direction={Math.sign(value.score - value.prevScore)}
                    size="8"
                    className="mr-1"
                  />
                  {value.prevScore})
                </p>
              </div>
            </div>
          </div>
        );
      });
    }
    return <LoadingSpinner />;
  };

  return (
    <>
      {renderDynamics()}
      <div className="flex flex-row items-center justify-center px-2 py-2 ">
        <HiOutlineInformationCircle
          className="text-base text-gray-600"
          size="24"
        />
        <div className="flex flex-row justify-between w-full mx-2 text-sm text-gray-600">
          {t('common:reportDynamicsInfo')}
        </div>
      </div>
    </>
  );
};

export default ReportDynamicsCol;
