import React from 'react'
import { svgWidth, svgHeight } from '../../utils/measurements'

interface GraphWrapperProps {}

const GraphWrapper: React.FC<GraphWrapperProps> = ({ children }) => {
  return (
    <svg width='100%' height='100%' viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
      {children}
    </svg>
  )
}

export default GraphWrapper
