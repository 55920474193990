import React, { ReactElement, ReactNode } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configuration';

interface PrivateRouteProps extends RouteProps {
  children: ReactNode;
}

const PrivateRoute = ({
  children,
  ...rest
}: PrivateRouteProps): ReactElement => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.loggedIn);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
