import {
  EmployeeState,
  EmployeeActions,
  FETCH_EMPLOYEES_PENDING,
  FETCH_EMPLOYEES_FAILED,
  FETCH_EMPLOYEES_SUCCESS,
  CHANGE_STATUS_SUCCESS,
  UPDATE_ROLES_SUCCESS,
  UPDATE_ROLES_FAILED,
  CHANGE_STATUS_FAILED,
} from './types';

const initialState: EmployeeState = {
  loading: false,
  error: undefined,
  entities: {},
  result: undefined,
};

const employeeReducer = (
  state = initialState,
  action: EmployeeActions
): EmployeeState => {
  switch (action.type) {
    case FETCH_EMPLOYEES_PENDING:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case FETCH_EMPLOYEES_SUCCESS:
    case CHANGE_STATUS_SUCCESS:
    case UPDATE_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        entities: action.payload?.entities,
        result: action.payload?.result,
      };
    case FETCH_EMPLOYEES_FAILED:
    case UPDATE_ROLES_FAILED:
    case CHANGE_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload?.error,
      };
  }
  return state;
};

export default employeeReducer;
