import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

type NavItemProps = {
  title: string;
  icon: ReactElement;
  path: string;
};

const NavItem = ({ title, icon, path }: NavItemProps): ReactElement => (
  <li className="my-2 mr-6 md:my-0">
    <NavLink
      to={path}
      className="flex flex-row justify-center py-1 pl-1 no-underline align-center md:py-3 hover:border-b-2 hover:text-gray-900 hover:border-orange-600"
      activeClassName="text-orange-600 border-b-2 border-orange-600 hover:text-orange-600"
      exact
    >
      {icon}
      <span className="px-2 pb-1 text-sm capitalize md:pb-0">{title}</span>
    </NavLink>
  </li>
);

export default NavItem;
