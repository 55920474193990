import { normalize } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';
import { Api, URL } from 'services/api';
import { fetchRolesSuccess, fetchRolesFailed } from './actions';
import RoleSchema from './schema';
import { FETCH_ROLES_PENDING } from './types';

function* fetchHandler() {
  try {
    const roles = yield Api.get(URL.ROLES).then((res) => res.data.roles);

    const { entities, result } = yield normalize(roles, [RoleSchema]);

    yield put(fetchRolesSuccess(entities.role, result));
  } catch (err) {
    yield put(fetchRolesFailed(err));
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onFetchEmployees() {
  yield takeLatest(FETCH_ROLES_PENDING, fetchHandler);
}

export default [onFetchEmployees];
