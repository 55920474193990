import { normalize } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';
import { Api, URL } from 'services/api';
import { fetchStatisticsSuccess, fetchStatisticsFailed } from './actions';
import statisticSchema from './schema';
import { FETCH_STATISTICS } from './types';

function* fetchHandler() {
  try {
    const res = yield Api.get(URL.KPI).then(({ data }) => data);

    const { entities, result } = yield normalize(res, [statisticSchema]);

    yield put(fetchStatisticsSuccess(entities.statistic, result));
  } catch (err) {
    yield put(fetchStatisticsFailed(err));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onFetchStatistics() {
  yield takeLatest(FETCH_STATISTICS, fetchHandler);
}

export default [onFetchStatistics];
