import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineLogout } from 'react-icons/hi';
import DropDown from 'components/atoms/DropDown';
import UserAvatar from 'components/atoms/UserAvatar';
import { useDispatch } from 'react-redux';
import { logOut } from 'store/auth';

const UserDropdown = (): ReactElement => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const menuItems = [
    {
      name: t('common:logOut'),
      textColor: 'red',
      icon: <HiOutlineLogout />,
      onClick: () => dispatch(logOut()),
    },
  ];

  return (
    <div className="flex items-center mr-3 focus:outline-none">
      <UserAvatar />
      <DropDown items={menuItems} />
    </div>
  );
};

export default UserDropdown;
