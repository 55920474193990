import {
  FetchAction,
  FetchFailedAction,
  FetchSuccessAction,
  FETCH_STATISTICS,
  FETCH_STATISTICS_FAILED,
  FETCH_STATISTICS_SUCCESS,
  StatisticEntities,
} from './types';

export const fetchStatistics = (): FetchAction => ({
  type: FETCH_STATISTICS,
});

export const fetchStatisticsSuccess = (
  entities: StatisticEntities,
  result: []
): FetchSuccessAction => ({
  type: FETCH_STATISTICS_SUCCESS,
  payload: {
    entities,
    result,
  },
});

export const fetchStatisticsFailed = (error: Error): FetchFailedAction => ({
  type: FETCH_STATISTICS_FAILED,
  payload: { error },
});
