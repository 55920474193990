import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configuration';
import LoadingMetricCard from 'components/organisms/LoadingMetricCard';
import MetricCard from 'components/molecules/MetricCard';
import { FaBatteryThreeQuarters, FaPercent } from 'react-icons/fa';
import { Statisctic } from 'store/statistics';
import i18n from 'services/i18n';

const KPI_CONTENT: {
  [key: string]: { name: string; icon: JSX.Element; color: string };
} = {
  answer_percent: {
    name: i18n.t('metrics:answerPercent'),
    icon: <FaPercent />,
    color: 'orange',
  },
  apdex: {
    name: i18n.t('metrics:apdex'),
    icon: <FaBatteryThreeQuarters />,
    color: 'blue',
  },
  motivation_score: {
    name: i18n.t('metrics:motivationScore'),
    icon: <FaBatteryThreeQuarters />,
    color: 'blue',
  },
};

const HomeViewMetricCards = (): ReactElement | null => {
  const { entities, result, loading } = useSelector(
    (state: RootState) => state.statics
  );

  const renderMetricCards = () =>
    result?.map((id) => {
      const current: Statisctic | null = entities ? entities[id] : null;
      if (current) {
        return (
          <div key={current.name} className="w-full p-3 md:w-1/2 xl:w-1/3">
            <MetricCard {...current} {...KPI_CONTENT[current.name]} />
          </div>
        );
      }
      return null;
    });

  const renderLoadingCards = () => {
    const arr = [];
    for (let index = 0; index < 3; index++) {
      arr.push(
        <div key={index} className="w-full p-3 md:w-1/2 xl:w-1/3">
          <LoadingMetricCard />
        </div>
      );
    }
    return arr;
  };

  if (loading) {
    return <>{renderLoadingCards()}</>;
  }

  if (result && entities && !loading) {
    return <>{renderMetricCards()}</>;
  }

  return null;
};

export default HomeViewMetricCards;
