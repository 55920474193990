import axios from 'axios';
import URL from './urls';

export const AUTH_HEADER_KEY = 'Authorization';

const axiosInstance = axios.create({
  baseURL: URL.BASE,
  timeout: 10000,
});

axiosInstance.defaults.headers = {
  common: {
    [AUTH_HEADER_KEY]: null,
    'Content-Type': 'application/json; charset=UTF-8',
    'Key-Inflection': 'camel',
  },
};

export const setHeader = (key: string, value: string): void => {
  axiosInstance.defaults.headers.common[key] = value;
};

export default axiosInstance;
