const API_BASE = process.env.REACT_APP_BASE_URL;
const ADMIN_PREFIX = '/admin';
export default {
  BASE: API_BASE,
  SIGNUP: `${ADMIN_PREFIX}/signup`,
  SESSION: `${ADMIN_PREFIX}/session`,
  EMPLOYEES: `${ADMIN_PREFIX}/employees`,
  REPORT: `${ADMIN_PREFIX}/reports`,
  KPI: `${ADMIN_PREFIX}/reports/kpi`,
  TRIANGLE_GRAPH: `/graphs/triangle_graph.json`,
  ROLES: `${ADMIN_PREFIX}/roles`,
  STATISTICS: `${ADMIN_PREFIX}/statistics`,
};
