import {
  FETCH_EMPLOYEES_FAILED,
  FETCH_EMPLOYEES_PENDING,
  FETCH_EMPLOYEES_SUCCESS,
  CHANGE_STATUS,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAILED,
  UPDATE_ROLES,
  UPDATE_ROLES_SUCCESS,
  UPDATE_ROLES_FAILED,
  FetchSuccessAction,
  UpdateRolesAction,
  FetchFailedAction,
  ChangeStatusAction,
  FetchAction,
  IEmployeeEntities,
} from './types';

export const fetchEmployees = (): FetchAction => ({
  type: FETCH_EMPLOYEES_PENDING,
});

export const fetchEmployeesSuccess = (
  entities: IEmployeeEntities,
  result: []
): FetchSuccessAction => ({
  type: FETCH_EMPLOYEES_SUCCESS,
  payload: {
    entities,
    result,
  },
});

export const fetchEmplyeesFailed = (error: Error): FetchFailedAction => ({
  type: FETCH_EMPLOYEES_FAILED,
  payload: { error },
});

export const changeEmployeeStatus = (
  id: number,
  status: string
): ChangeStatusAction => ({
  type: CHANGE_STATUS,
  payload: {
    id,
    status,
  },
});

export const changeStatusSuccess = (
  entities: IEmployeeEntities,
  result: []
): FetchSuccessAction => ({
  type: CHANGE_STATUS_SUCCESS,
  payload: {
    entities,
    result,
  },
});

export const changeStatusFailed = (error: Error): FetchFailedAction => ({
  type: CHANGE_STATUS_FAILED,
  payload: { error },
});

export const updateEmployeeRolesPending = (
  id: number,
  roles: string[]
): UpdateRolesAction => ({
  type: UPDATE_ROLES,
  payload: { id, roles },
});

export const updateEmployeeRolesSuccess = (
  entities: IEmployeeEntities,
  result: number[]
): FetchSuccessAction => ({
  type: UPDATE_ROLES_SUCCESS,
  payload: {
    entities,
    result,
  },
});

export const updateEmployeeRolesFailed = (error: Error): FetchFailedAction => ({
  type: UPDATE_ROLES_FAILED,
  payload: { error },
});
